"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReduxFetchState = void 0;
const utils_1 = require("./utils");
class ReduxFetchState {
    constructor(name, initialValues = {}) {
        this.generateReducer = () => {
            this.reducer = (state = this.initialState, action) => {
                if (this.handlers.hasOwnProperty(action.type)) {
                    return this.handlers[action.type](state, action);
                }
                else {
                    return state;
                }
            };
        };
        this.addCustomAction = (actionKey, actionName, handler) => {
            const actionType = `${this.name}/${actionName}`;
            this.actionTypes = Object.assign(Object.assign({}, this.actionTypes), { [actionKey]: actionType });
            this.actions = Object.assign(Object.assign({}, this.actions), { [actionKey]: (payload) => ({
                    type: this.actionTypes[actionKey],
                    payload,
                }) });
            this.handlers = Object.assign(Object.assign({}, this.handlers), { [actionType]: handler });
            this.generateReducer();
        };
        this.name = name;
        this.initialState = Object.assign({ loading: false, loaded: false, data: null, error: null, form: null }, initialValues);
        this.actionTypes = (0, utils_1.actionTypesGenerator)(name);
        this.actions = {
            load: (payload, isSilent) => ({
                type: this.actionTypes.load,
                payload,
                isSilent,
            }),
            loadSuccess: payload => ({
                type: this.actionTypes.loadSuccess,
                payload,
            }),
            loadFailure: (payload, removeData) => ({
                type: this.actionTypes.loadFailure,
                payload,
                removeData,
            }),
            resetCache: () => ({
                type: this.actionTypes.resetCache,
            }),
            resetForm: () => ({
                type: this.actionTypes.resetForm,
            }),
        };
        this.handlers = {
            [this.actionTypes.load]: (state, action) => {
                return Object.assign(Object.assign({}, state), { loading: !action.isSilent, loaded: action.isSilent, error: null, form: action.payload });
            },
            [this.actionTypes.loadSuccess]: (state, action) => {
                return Object.assign(Object.assign({}, state), { loading: false, loaded: true, form: null, error: null, data: action.payload });
            },
            [this.actionTypes.loadFailure]: (state, action) => {
                return Object.assign(Object.assign({}, state), { loading: false, loaded: false, error: action.payload, data: action.removeData ? null : state.data });
            },
            [this.actionTypes.resetCache]: () => this.initialState,
            [this.actionTypes.resetForm]: (state) => {
                return Object.assign(Object.assign({}, state), { form: null });
            },
        };
        this.generateReducer();
    }
}
exports.ReduxFetchState = ReduxFetchState;
exports.default = ReduxFetchState;
